import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { change } from "../state/Nav";
import { TextBody, TextBox, TextHeader } from "../Styles";
import { hideKrpano } from "../Util";

export default function Visit() {
    const dispatch = useDispatch();
    dispatch(change("visit"));

    hideKrpano();

     return (
        <TextBox>
            <TextHeader>
                Öffnungszeiten
            </TextHeader>
            <TextBody>
                Nach Voranmeldung.
            </TextBody>
            <TextHeader marginTop="30px">
                Kontakt
            </TextHeader>
            <TextBody>
                Email: info@kunstraum-rudolfsplatz.at
            </TextBody>
            <TextBody>
                Tel.: +43 (0)681 20568818
            </TextBody>
            <TextBody>
                Website: <Link target="_self" href="https://www.kunstraum-rudolfsplatz.at">
                    <Typography variant="body2" sx={{ fontSize: "0.925rem", lineHeight: "1.4rem", display: "inline", color: "#b7979c", textDecoration: "underline #b7979c" }}>www.kunstraum-rudolfsplatz.at</Typography></Link>
            </TextBody>
            <TextHeader marginTop="30px">
                Erreichbarkeit und Anfahrt
            </TextHeader>
            <TextBody>
                Rudolfsplatz 3, Top 9
            </TextBody>
            <TextBody>
                1010 Wien
            </TextBody>
            <TextBody>
                <Link target="_blank" href="https://www.google.at/maps/place/Rudolfspl.+3,+1010+Wien/@48.214747,16.3694579,16z/data=!3m1!4b1!4m6!3m5!1s0x476d07a39b525807:0x97c3a6afcb445d35!8m2!3d48.2147435!4d16.3720328!16s%2Fg%2F11csn9znkn?hl=de&entry=ttu&g_ep=EgoyMDI0MDkyOS4wIKXMDSoASAFQAw%3D%3D">
                    <Typography variant="body2" sx={{ fontSize: "0.925rem", lineHeight: "1.4rem", display: "inline", color: "#b7979c", textDecoration: "underline #b7979c" }}>Karte</Typography>
                </Link>
            </TextBody>
        </TextBox>
    );
}