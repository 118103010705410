import { Box, Typography, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { change } from "../state/Nav";
import { TextBody, TextBox, TextHeader } from "../Styles";
import { hideKrpano } from "../Util";

export default function Oeuvre() {
        const dispatch = useDispatch();
        dispatch(change("art"));

        hideKrpano();

        return (<Box sx={{ paddingBottom: "60px" }}>

                <TextBox>
                        <TextHeader>
                                Bild und Wort
                        </TextHeader>
                        <TextBody marginTop="2px">
                                Man kann die Bilder von Anna Laour wohl am besten als überdimensionale Einzelblätter verschiedener illustrierter Bücher begreifen.
                                Die Veranschaulichung des geschriebenen Wortes durch künstlerisch gestaltete Bilder kann ja auf eine bis in die Antike zurückreichende Tradition zurückblicken,
                                und in ihren Grafiken führt die Künstlerin diese Tradition in gewandelter Form weiter, wobei sie auf die drei bekanntesten, eigentlich grundsätzlich unterschiedlichen Vertreter
                                der Bilderzählung Bezug nimmt: auf die illuminierten religiösen Handschriften des Mittelalters, auf die literarischen Malerbücher des frühen 20. Jh. von Picasso, Matisse, Klee und
                                vielen anderen, aber auch auf die populären Kinderbilderbücher.
                        </TextBody>
                        <TextHeader marginTop="20px">
                                Buchmalerei
                        </TextHeader>
                        <TextBody marginTop="2px">
                                Die häufige Wahl von mythologischen, teilweise christlichen Motiven und die Verflechtung von ornamentalen und figurativen Elementen in ihren Bildern verweist auf die
                                mittelalterlichen Buchmalerei, deren aufwendige materielle Ausführung auch die Betonung der stofflichen Qualität der Werke Anna Laours motiviert: In ihren großformatigen,
                                auf grobstrukturiertem Aquarellkarton ausgeführten Bildern wendet sie nämlich eine spezielle, selbst entwickelte Technik der Mischung von Aquarellfarben und Gouache an,
                                welche die leuchtende Flächigkeit der ersteren mit der matten Faktur der letzteren kontrastiert und gleichzeitig die natürliche Musterung des Bildträgers hervortreten lässt.
                                Durch die scharfe Flächenkonturierung setzt sie zudem das malerische Medium des Aquarells in einem grafischen Modus ein, wodurch paradoxer Weise die stoffliche Wirkung der Farben
                                zusätzlich verstärkt wird.
                        </TextBody>
                        <TextHeader marginTop="20px">
                                Künstler- und Kinderbücher
                        </TextHeader>
                        <TextBody marginTop="2px">
                                Die Künstlerin interpretiert die Schriftwerke, denen ihre Werkzyklen gewidmet sind, spielerisch mit zahlreichen Bilderfindungen und orientiert sich in dieser Hinsicht an den freien
                                Textinterpretationen der modernen Künstlerbücher: Häufig wird erzählerische Inhalt motivisch und stilistisch so weitgehend transformiert, dass dessen Entschlüsselung erst nach
                                längerer eingehender Bildbetrachtung gelingt.
                                An Kinderbilderbücher gemahnen schließlich die naive Stilisierung, die häufige Wahl von episodischen Motiven aus Tier- und Pflanzenwelt, der kräftige Farbakkord sowie die Betonung der Flächigkeit der Darstellung.
                        </TextBody>
                        <TextHeader marginTop="20px">
                                Die Lust am Imaginieren
                        </TextHeader>
                        <TextBody marginTop="2px">
                                Die Arbeiten Anna Laours entspringen der unbändigen Lust und Freude der Künstlerin an der kunstvollen Imagination – oder aktiv formuliert, am kunstvollen Imaginieren – und zwar im
                                vollen Bedeutungsumfang des Begriffes: Zunächst führt die lateinische Wurzel „Imago“, d. h. das Bild, zur Bedeutung von „imaginieren“ als „in Bildern denken“, „sich als Bild vorstellen“
                                bzw. nach außen gewendet „als Bild gestalten, als Bild ausdrücken oder sinnfällig machen“. Im weiteren, vielleicht gebräuchlicheren Sinn steht der Begriff synonym für das
                                „phantasievolle Ausdenken oder Erfinden“. In Kombination resultieren diese beiden Bedeutungen in der Definition von „Imagination“ als phantasievolles Ausdenken oder Erfinden
                                von bildlichen Inhalten und Motiven, aber auch von bildlichen Ausdrucksformen und Gestaltungsweisen. Und eben der freudvollen Lust an diesem kreativen Prozess entspringt das
                                gesamte Werk der Künstlerin – so unterschiedlich die Arbeiten im Einzelnen auch erscheinen mögen.
                        </TextBody>
                        <TextHeader marginTop="20px">
                                Verrätseln und Entschlüsseln
                        </TextHeader>
                        <TextBody marginTop="2px">
                                In diesem Prozess dienen die benutzten literarischen Texte gewissermaßen als inspirierender Rohstoff. Für diese Funktion eignen sich ihre erzählerischen Inhalte vor allem deshalb,
                                weil sie selbst bereits Ausfluss und Resultat der – allerdings nicht-bildlichen – Imagination der jeweiligen Autoren sind.
                                Diese Inhalte werden im Zuge der bildlichen Verarbeitung aber weitgehend transformiert und so zwangsläufig verrätselt.
                                Durch die Wahl bekannter, oft klassischer Schriftwerke als Sujets wird aber ein leicht zugänglicher assoziativer Kontext etabliert – d. h. ein assoziativer Kontext,
                                der für die meisten Betrachter leicht verfügbar ist – innerhalb dessen die Bilder les- und interpretierbar bleiben.
                                Zusätzliche Hilfestellung bei der Entschlüsselung leisten in der Ausstellung die textlichen und multimedialen Bildbeschreibungen ausgewählter Werke.
                        </TextBody>
                        <TextHeader marginTop="20px">
                                Kunsthandwerk
                        </TextHeader>
                        <TextBody marginTop="2px">
                                Bei aller phantasievollen Gestaltung ist aber auch unübersehbar, dass die Arbeiten von der Kenntnis kompositorischer Regeln profitiert haben und dass sie mit einer speziellen,
                                selbst ausgeklügelten Technik gemalt und mit fast zwanghafter Präzision ausgeführt sind. Es handelt sich dabei aber nicht um ein willkürliches Festhalten an Qualitäten,
                                die heute in der bildenden Kunst als obsolet – oder im besten Fall kunsthandwerklich – angesehen werden. Vielmehr bilden genau diese Qualitäten die Voraussetzungen dafür,
                                dass die Imagination der Künstlerin ihre volle visuelle Wirkung entfalten kann. Nicht zuletzt durch die überlegten Bildkompositionen geht von den Bildern auch eine ausgesprochen
                                dekorative Wirkung aus.
                        </TextBody>
                </TextBox>
        </Box>
        );
}