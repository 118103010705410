import { Box, Typography, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { change } from "../state/Nav";
import { TextBody, TextBox, TextHeader } from "../Styles";
import { hideKrpano } from "../Util";

export default function Artist() {
        const dispatch = useDispatch();
        dispatch(change("artist"));

        hideKrpano();

        return (
                <Box sx={{paddingBottom:"60px"}}>
                        <TextBox>
                                <TextHeader>
                                        Vita
                                </TextHeader>
                                <TextBody marginTop="2px">
                                        Anna Laour stammt aus Sankt Petersburg, Russland, lebt und arbeitet aber seit über 20 Jahren in Wien.
                                        Sie ist Absolventin der staatlichen Muchina-Hochschule für angewandte Kunst und industrielles Design in Sankt Petersburg.
                                        <img src={"/artist.png"} style={{ display: "block", maxWidth: "600px", height: "auto", margin: "auto", paddingTop: "20px" }} />
                                </TextBody>
                                <Box component="div" sx={{ paddingTop: "10px", display: "flex", alignItems: "bottom", justifyContent: "center" }}>
                                        <Typography gutterBottom variant="subtitle1" >
                                                Die Künstlerin und ihr Alter Ego
                                        </Typography>
                                </Box>
                        </TextBox>
                </Box>
        );
}